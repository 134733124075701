import { useState } from "react";
import "./Preguntas.css";
import abrirPregunta from "../../../../../img/abrirPregunta.svg";
import cerrarPregunta from "../../../../../img/cerrarPregunta.svg";
import { t } from "i18next";
import AccordionPreguntas from "./AccordionPreguntas";
import QuienesSomosSingleCardImg from "../quienesSomos/QuienesSomosSingleCardImg";
export default function Preguntas() {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const handleQuestion = (index) => {
    setActiveQuestion(index === activeQuestion ? null : index);
  };

  return (
    <section className="bg-[#151820] m-0 p-0">
      <div className="container-faq">
        <div className="md:grid md:grid-cols-2 md:items-center md:gap-32">
          <p className="text-left font-hanken md:text-[4.3rem]">{t("PreguntasTitulo")}</p>
          <p className="font-montserrat md:text-[1rem]">{t("descripcionPreguntas")}</p>
        </div>
        <div className="md:flex md:justify-between md:items-center md:gap-20">
          <div className="md:w-1/2">
            <AccordionPreguntas
              title={t("pregunta0")}
              content={t("respuesta0")}
            />
            <AccordionPreguntas
              title={t("pregunta1")}
              content={t("respuesta1")}
            />
            <AccordionPreguntas
              title={t("pregunta2")}
              content={t("respuesta2")}
            />
            <AccordionPreguntas
              title={t("pregunta3")}
              content={t("respuesta3")}
            />
            <AccordionPreguntas
              title={t("pregunta4")}
              content={t("respuesta4")}
            />
          </div>
          <div className="hidden md:max-w-[50rem] md:mx-auto md:flex md:justify-center md:items-center"> 
            <QuienesSomosSingleCardImg />
          </div>
        </div>
      </div>
    </section>
  );
}
