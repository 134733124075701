import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import './AboutUsCarrusel.css';
import CommentCard from './CommentCard';
import { useRef } from 'react';


const AboutUsCarrusel = () => {

   const swiperRef = useRef(null);

  // Funciones para manejar la navegación
  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="bg-transparent text-white pt-0">
      <div className="max-w-[90vw] mx-auto px-4">
        {/* Encabezado */}
        {/* Carrusel */}

          <button onClick={handlePrev} className="custom-prev-button absolute left-[5%] top-[38%] -translate-y-1/2 -translate-x-1/2 z-10 w-10 h-10 bg-gray-800 rounded-full hidden md:flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          
          <button onClick={handleNext} className="custom-next-button absolute right-[5%] top-[38%] -translate-y-1/2 translate-x-1/2 z-10 w-10 h-10 bg-gray-800 rounded-full hidden md:flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>

        <Swiper
          ref={swiperRef}
          modules={[Navigation]}
          spaceBetween={20}
          slidesPerView={3}
          navigation
          loop={true}
          className="relative"
          breakpoints={{
            1024: {
              slidesPerView: 3,
              spaceBetween: 20
            }
          }}
        >
          {/* Aquí van tus tarjetas existentes */}
          <SwiperSlide>
            <CommentCard />
          </SwiperSlide>
          <SwiperSlide>
            <CommentCard />
          </SwiperSlide>
          <SwiperSlide>
            <CommentCard />
          </SwiperSlide>
          <SwiperSlide>
            <CommentCard />
          </SwiperSlide>
          {/* Repite SwiperSlide para cada tarjeta */}
        </Swiper>
      </div>
    </div>
  );
};

export default AboutUsCarrusel;