import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SingleCard.css';

function SingleCard({ t, user, title, description, button, buttonText, icon }) {
  return (
    <section>
      <div className="card-main">
        <div className="card-content">
          <div className='icon-card'>
            <FontAwesomeIcon icon={icon} /> {/* Use the icon prop */}
          </div>
          <div>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          {button && (
            <button className='button-card' onClick={() => (window.location.href = "/register")}>
              {buttonText}
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

export default SingleCard;