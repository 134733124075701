import iconoComunidad from "../../../../../img/icono-comunidad.svg";
import { t } from "i18next";
import QuienesSomosSingleCardImg from "./QuienesSomosSingleCardImg";
import QuienesSomosSingleCard from "./QuienesSomosSingleCard";

export default function QuienesSomos() {
  return (
    <main>
      <section className="grid place-content-center place-items-center w-[90%] mx-auto">
        <div className="md:hidden md:-z-10">
          <QuienesSomosSingleCardImg />
        </div>
        <div className="md:flex ">
          <div className=" md:w-1/2">
            <div className="text-left text-white mt-8">
              <h1 className="text-[1.9rem] md:text-[4.1rem] font-hanken md:text-left">
                {t("tituloAboutUs")}
              </h1>
              <p className="font-montserrat mx-auto text-[0.9rem] md:text-[1.1rem] mt-8 md:my-16 text-opacity-70">
                {t("descripcionAboutUs")}
              </p>
              <div className="w-full hidden md:block">
                <button className="rounded-lg border-none cursor-pointer py-3 px-8 text-base font-medium text-white bg-gradient-to-r from-[#0084ff] to-[#003dd7] transition-all duration-500 hover:bg-gradient-to-t">
                  Crear cuenta
                </button>
              </div>
            </div>
            <div className="w-full my-6 grid grid-cols-1 md:grid-cols-3 place-content-center place-items-center text-center justify-between gap-8 md:gap-8 md:ml-0 md:z-10 text-white  md:w-[160%]">
              <QuienesSomosSingleCard
                title={t("Herramientaspublicidad")}
                description={t("HerramientaspublicidadText")}
              />
              <QuienesSomosSingleCard
                title={t("ConvierteteAfiliado")}
                description={t("ConvierteteAfiliadoAboutUstext")}
              />
              <QuienesSomosSingleCard
                title={t("Depositadinero")}
                description={t("DepositadineroText")}
              />
            </div>
          </div>
          <div className="hidden md:flex md:w-1/2 md:place-content-center md:items-center md:h-[50vh]">
            <QuienesSomosSingleCardImg />
          </div>
        </div>

        <div className="w-full md:hidden">
          <button className="rounded-lg border-none cursor-pointer py-3 px-8 text-base font-medium text-white bg-gradient-to-r from-[#0084ff] to-[#003dd7] transition-all duration-500 hover:bg-gradient-to-t">
            Crear cuenta
          </button>
        </div>
      </section>
    </main>
  );
}
