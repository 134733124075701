import star from "../../../../../img/star.svg";
import escudo from "../../../../../img/escudo.svg";
import send from "../../../../../img/send.svg";
import dinero from "../../../../../img/dinero.svg";
import SingleCard from "../clusters/SingleCard";
import { faCoins, faLink } from "@fortawesome/free-solid-svg-icons";

const GetStarted = ({ t, user }) => {
  return (
    <main className="bg-[#151820]">
      <section className="container  md:w-[100vw] mx-auto p-4">
        <div className="md:flex md:items-baseline md:justify-center md:gap-8 mb-8">
          <h1 className="text-left  font-medium mb-[1.8rem] text-[1.9rem] md:text-[4.2rem] text-white font-hanken">
            {t("tituloGetStarted")}
          </h1>
          <p className="font-montserrat  text-[0.9rem] md:w-[60%]">
            {t("subtituloGetStated")}
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 md:mb-0">
          <div className="border-[1px] border-gray-500 p-[0.5rem] rounded-xl ">
            <div className="flex flex-col gap-4  justify-start items-start p-3 rounded-lg shadow-md bg-[#2c2c2c] bg-opacity-15 backdrop-blur-md">
              <img
                src={escudo}
                alt="Secured Icon JikesShop"
                className="w-[2rem] h-[2rem] bg-[#0084FF] rounded-full"
              />
              <div className=" w-full">
                <h3 className="text-[1rem] font-hanken font-semibold">
                  {t("asegurado")}
                </h3>
                <p className="text-[0.8rem] font-montserrat font-normal">
                  {t("codificaciones")}
                </p>
              </div>
            </div>
          </div>
          <div className="border-[1px] border-gray-500 p-[0.5rem] rounded-xl ">
            <div className="flex flex-col gap-4  justify-start items-start p-3 rounded-lg shadow-md bg-[#2c2c2c] bg-opacity-15 backdrop-blur-md">
              <img
                src={send}
                alt="Secured Icon JikesShop"
                className="w-[2rem] h-[2rem] bg-[#0084FF] rounded-full"
              />
              <div>
                <h3 className="text-[1rem] font-hanken font-semibold">
                  {t("automatizad")}
                </h3>
                <p className="text-[0.8rem] font-montserrat font-normal">
                  {t("proceso")}
                </p>
              </div>
            </div>
          </div>
          <div className="border-[1px] border-gray-500 p-[0.5rem] rounded-xl ">
            <div className="flex flex-col gap-4  justify-start items-start p-3 rounded-lg shadow-md bg-[#2c2c2c] bg-opacity-15 backdrop-blur-md">
              <img
                src={star}
                alt="Secured Icon JikesShop"
                className="w-[2rem] h-[2rem] bg-[#0084FF] rounded-full"
              />
              <div>
                <h3 className="text-[1rem] font-hanken font-semibold">
                  {t("GanaDinero")}
                </h3>
                <p className="text-[0.8rem] font-montserrat font-normal">
                  {t("ganaDineroText")}
                </p>
              </div>
            </div>
          </div>
          <div className="border-[1px] border-gray-500 p-[0.5rem] rounded-xl ">
            <div className="flex flex-col gap-4  justify-start items-start p-3 rounded-lg shadow-md bg-[#2c2c2c] bg-opacity-15 backdrop-blur-md">
              <img
                src={dinero}
                alt="Secured Icon JikesShop"
                className="w-[2rem] h-[2rem] bg-[#0084FF] rounded-full"
              />
              <div>
                <h3 className="text-[1rem] font-hanken font-semibold">
                  {t("ServiciosBaratos")}
                </h3>
                <p className="text-[0.8rem] font-montserrat font-normal">
                  {t("ServiciosBaratosText")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex md:m-0 md:gap-8 md:mx-auto">
          <SingleCard
            t={t}
            user={user}
            title={t("GanaDinero")}
            description={t("ganaDineroText2")}
            button={true}
            buttonText={t("Dashboard")}
            icon={faCoins}
          />
          <SingleCard
            t={t}
            user={user}
            title={t("AñadeFondosCuenta")}
            description={t("depositaDineroCluster")}
            button={true}
            buttonText={t("Dashboard")}
            icon={faLink}
          />
        </div>
      </section>
    </main>
  );
};

export default GetStarted;
