import React from 'react'
import discordBackground from "../../../img/discord.png";
import discordDesktopBackground from "../../../img/discordDesktop.png";

function Hero({ t, user}) {
  return (
    <div className="w-[100%] h-[100vh] bg-[#151820] flex justify-center items-center">
      <div className="container-segundaParte">
        <div>
          <img src={discordBackground} alt="Img header JikesShop" />
        </div>
      </div>
      <div className="z-10 flex flex-col md:flex-row justify-between items-start md:items-center border-white p-4 w-[90%]">
        <div className="">
          <p className="font-hanken font-black text-[2.3rem] md:text-[7.5rem] leading-3 md:leading-10">
            {t("TituloPrincipalHeader")}
          </p>
          <p className="bg-gradient-custom font-hanken font-black text-[2.3rem] md:text-[7.5rem]">
            {t("TituloSecondaryHeader")}
          </p>
          <p className="font-montserrat font-light text=[0.9rem] mt-10">
            {t("subtituloHeader")}
          </p>
          <div className="mx-auto btns-header">
            {!user && (
              <button onClick={() => (window.location.href = "/register")}>
                {t("ComienzabtnHeader")}
              </button>
            )}
            {!user ? (
              <button onClick={() => (window.location.href = "/register")}>
                {t("Dashboard")}
              </button>
            ) : (
              <button onClick={() => (window.location.href = "/add-funds")}>
                {t("DepositFundsbtn")}
              </button>
            )}
          </div>
        </div>
        <p className="hidden md:block">
          <img src={discordDesktopBackground} alt="Img header JikesShop" />
        </p>
      </div>
    </div>
  );
}

export default Hero