import { useEffect, useState } from "react";
import Header from "./common/header/Header";
import "./Home.css";
import Separator from "./common/separator/Separator";
import GetStarted from "./common/getStarted/GetStarted";
import Clusters from "./common/clusters/Clusters";
import Control from "./common/control/Control";
import AboutUs from "./common/aboutUs/AboutUs";
import Footer from "./common/footer/Footer";
import StartService from "./common/startService/StartService";
import { handleLanguageChange } from "../../../Utils/language";
import { handleIntersection } from "../../../Utils/handleAnimationScrollHome";
import ScrollToTop from "../../../Utils/ScrollToTop";
import NavbarHome from "../../navbar/NavbarHome";
// Utils- NOTIFICACIONES Y LOGOUT
import { desactivarModals } from "./../../../Utils/Home/Home.js";
import { t } from "i18next";
import QuienesSomos from "./common/quienesSomos/QuienesSomos.jsx";
//import StoreProducts from "./common/StoreProducts/StoreProducts.jsx";
import Preguntas from "./common/Preguntas/Preguntas.jsx";
import StatsSection from "./StatsSection/StatsSection.jsx";
import QuienesSomosSingleCardImg from "./common/quienesSomos/QuienesSomosSingleCardImg.jsx";
import Hero from "./Hero.jsx";

export default function Home({
  menuBars,
  setMenuBars,
  user,
  setUser,
  seccionLogOut,
  seccionNotify,
  setSeccionNotify,
  setSeccionLogOut,
  notifiacionesItem,
}) {
  useEffect(() => {
    const mains = document.querySelectorAll("main");
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      // rootMargin: "10px",
      // threshold: 0.4, // Change this threshold as needed
    });
    mains.forEach((section) => {
      observer.observe(section);
    });
    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className="container-home" onClick={() => desactivarModals(setMenuBars, setSeccionNotify, setSeccionLogOut)}>
        <ScrollToTop />
        <Hero t={t} user={user} />
        {/* <Header t={t} user={user} /> */}
        <StatsSection />
        {/* <Separator t={t} /> */}
        <GetStarted t={t} user={user} />
        {/* <Clusters t={t} user={user} /> */}
        <QuienesSomos />
        {/* <Control t={t} /> */}
        {/* <StoreProducts t={t} user={user} /> */}
        <AboutUs t={t} />
        <Preguntas />
        {/* <StartService t={t} /> */}
        <Footer t={t} handleLanguageChange={handleLanguageChange} />
      </div>
    </>
  );
}
