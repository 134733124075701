import React from 'react';

function QuienesSomosSingleCard({ title, description }) {
  return (
    <div className="border-[1px] border-gray-500 p-[0.5rem] rounded-xl md:h-full md:relative md:w-[100%] ">
      <div className="flex flex-col gap-4 justify-start items-start p-3 rounded-lg shadow-md bg-[#2c2c2c] bg-opacity-15 backdrop-blur-md">
        <h1 className="font-hanken text-[1rem] text-white">{title}</h1>
        <p className="font-montserrat text-left text-[0.8rem] text-white">{description}</p>
      </div>
    </div>
  );
}

export default QuienesSomosSingleCard;