import React from 'react'
import quienesSomosImg from '../../../../../img/quienes_somos.png'

function QuienesSomosSingleCardImg() {
  return (
    <div className="border-[1px] border-gray-500 p-[0.5rem] rounded-xl ">
      <div className="flex flex-col gap-4  justify-start items-start p-3 rounded-lg shadow-md bg-[#2c2c2c] bg-opacity-15 backdrop-blur-md">
        <img className='md:w-[32rem]' src={quienesSomosImg} alt="Quienes Somos" />
      </div>
    </div>
  )
}

export default QuienesSomosSingleCardImg