import React from 'react';

const StatsSection = () => {
  return (
    <main className="md:m-0 md:p-0">
      <div className="flex flex-col items-center justify-center md:justify-center md:gap-[2rem] md:flex-row md:w-[100%] md:overflow-x-hidden">
        <hr className="border-none h-[1px] w-[350px] bg-gradient-to-r from-transparent via-gray-500 md:m-0 md:p-0 to-transparent z-1" />
      </div>
      <section className="flex flex-row justify-between md:justify-center md:bg-[#151820] md:p-[2rem] md:gap-[4rem] md:w-fit items-center bg-transparent text-white w-[100%] overflow-x-hidden z-1">
        <div className="flex flex-col items-center text-center min-w-[80px] ">
          <span className="text-[1.25rem] md:text-[3.15rem] md:font-medium font-bold bg-clip-text text-transparent bg-gradient-to-l from-blue-500 to-blue-900 mb-[5px]">
            15+
          </span>
          <p className="text-[0.5rem] md:text-[1rem] font-montserrat m-0 whitespace-nowrap">
            Productos
          </p>
        </div>
        <div className="border-l border-gray-700 h-[30px] mx-[5px]"></div>

        <div className="flex flex-col items-center text-center min-w-[80px] mx-[10px]">
          <span className="text-[1.25rem] md:text-[3.15rem] md:font-medium font-bold bg-clip-text text-transparent bg-gradient-to-l from-blue-500 to-blue-900 mb-[5px]">
            200
          </span>
          <p className="text-[0.5rem] md:text-[1rem] m-0 whitespace-nowrap">
            Clientes satisfechos
          </p>
        </div>
        <div className="border-l border-gray-700 h-[30px] mx-[5px]"></div>

        <div className="flex flex-col items-center text-center min-w-[80px] mx-[10px]">
          <span className="text-[1.25rem] md:text-[3.15rem] md:font-medium font-bold bg-clip-text text-transparent bg-gradient-to-l from-blue-500 to-blue-900 mb-[5px]">
            2 años
          </span>
          <p className="text-[0.5rem] md:text-[1rem] m-0 whitespace-nowrap">
            Ofreciendo soluciones
          </p>
        </div>
        <div className="border-l border-gray-700 h-[30px] mx-[5px]"></div>

        <div className="flex flex-col items-center text-center min-w-[80px] mx-[10px]">
          <span className="text-[1.25rem] md:text-[3.15rem] md:font-medium font-bold bg-clip-text text-transparent bg-gradient-to-l from-blue-500 to-blue-900 mb-[5px]">
            +$20K
          </span>
          <p className="text-[0.5rem] md:text-[1rem] m-0 whitespace-nowrap">
            Beneficios de clientes
          </p>
        </div>
      </section>
      <div className="flex  flex-col items-center justify-center md:justify-center md:flex-row md:w-[100%] md:overflow-x-hidden">
        <hr className="border-none h-[1px] w-[350px] bg-gradient-to-r from-transparent via-gray-500 md:m-0 md:p-0 to-transparent z-1" />
      </div>
    </main>
  );
};

export default StatsSection;