import logoBanner from "../../../../../img/logoBanner.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";

export default function Footer({ t, handleLanguageChange }) {
  return (
    <section className="w-full m-0 bg-[#151820] flex flex-col justify-center items-center">
      <div className="w-full flex justify-center items-center">
        <div className="w-[80%]  flex flex-col justify-around items-start">
          <div className='w-full h-full'>
            <img src={logoBanner} alt="logoBlanco" className="" />
          </div>
          <section className="w-full flex flex-col md:flex-row justify-between items-start gap-4">
            <div className="w-full">
              <h2 className="text-white text-left font-hanken text-[1rem]">Por definir</h2>
              <ul className="text-white list-none p-0 font-montserrat text-[0.9rem] font-light mb-[1.5rem]">
                <li className="my-[0.5rem]">About us</li>
                <li className="my-[0.5rem]">Services</li>
                <li className="my-[0.5rem]">Testimonials</li>
                <li className="my-[0.5rem]">Contact Us</li>
              </ul>
            </div>
            <div className="w-full">
              <h2 className="text-white text-left font-hanken text-[1rem]">Por definir</h2>
              <ul className="text-white list-none p-0 font-montserrat text-[0.9rem] font-light mb-[1.5rem]">
                <li className="my-[0.5rem]">About us</li>
                <li className="my-[0.5rem]">Services</li>
                <li className="my-[0.5rem]">Testimonials</li>
                <li className="my-[0.5rem]">Contact Us</li>
              </ul>
            </div>
            <div className="w-full">
              <h2 className="text-white text-left font-hanken text-[1rem]">Por definir</h2>
              <ul className="text-white list-none p-0 font-montserrat text-[0.9rem] font-light mb-[1.5rem]">
                <li className="my-[0.5rem]">About us</li>
                <li className="my-[0.5rem]">Services</li>
                <li className="my-[0.5rem]">Testimonials</li>
                <li className="my-[0.5rem]">Contact Us</li>
              </ul>
            </div>
            <div className="w-full">
              <h2 className="text-white text-left font-hanken text-[1rem]">Contáctanos</h2>
              <ul className="text-white list-none p-0 font-montserrat text-[0.9rem] font-light mb-[1.5rem]">
                <div className="flex justify-start items-center gap-[0.4rem]">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <li className="my-[0.5rem]">hello@jikesshop.com</li>
                </div>
                <div className="flex justify-start items-center gap-[0.4rem]">
                  <FontAwesomeIcon icon={faLocationDot} />
                  <li className="my-[0.5rem]">Dirección, 64, Madid, España</li>
                </div>
                <div className="flex justify-start items-center gap-[0.4rem]">
                  <FontAwesomeIcon icon={faPhone} />
                  <li className="my-[0.5rem]">+34 123 456 789</li>
                </div>
              </ul>
            </div>
          </section>
        </div>
      </div>
      <div className="w-full flex justify-around items-center">
        <div className="w-[80%] flex justify-center items-center gap-[1rem] font-poppins text-white opacity-[0.6] text-[400]">
          <p>© 2024 JikesShop. All Rights Reserved.</p>
        </div>
      </div>
      <select onChange={handleLanguageChange} className="absolute right-[5%] top-[70%]">
        <option>{t(`changeIdioma`)}</option>
        <option value="en">English</option>
        <option value="es">Español</option>
      </select>
    </section>
  );
}
