import React from 'react'
import './CommentCard.css'
import persona1 from "../../../../../img/persona1.webp";
import persona2 from "../../../../../img/persona2.webp";
import persona3 from "../../../../../img/persona3.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

function CommentCard() {
  return (
    <section className='container-comment-card'>
      <div className="container-comment-header">
        <img src={persona1} alt="persona1" className="personas" />
        <div className="nombres-about-us">
          <h3>Maria Fernandez</h3>
          <p>Trader</p>
      </div>
      </div>
      <div className='container-comment'>
        <p>Become an affiliate of our store and earn 30% lifetime earnings.</p>
      </div>
      <div className='container-stars'>
        <FontAwesomeIcon icon={faStar} />
        <FontAwesomeIcon icon={faStar} />
        <FontAwesomeIcon icon={faStar} />
        <FontAwesomeIcon icon={faStar} />
        <FontAwesomeIcon icon={faStar} />
      </div>
    </section>
  )
}

export default CommentCard