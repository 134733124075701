import sombraAboutUs from "../../../../../img/sombraAboutUs.webp";
import comillas from "../../../../../img/comillas.webp";
import persona1 from "../../../../../img/persona1.webp";
import persona2 from "../../../../../img/persona2.webp";
import persona3 from "../../../../../img/persona3.webp";
import CommentCard from "./CommentCard";
import QuienesSomosSingleCardImg from "../quienesSomos/QuienesSomosSingleCardImg";
import AboutUsCarrusel from "./AboutUsCarrusel";

const AboutUs = ({ t }) => {
  return (
    <main className="m-0 p-0 bg-[#151820]">
      <div className="relative bg-[#151820]">
        <section className="w-[90%] flex flex-col justify-center items-center pb-[5%] my-[5%] mx-auto md:mt-0 md:pt-24">
          <div className="w-full mb-[5%] text-white  md:flex md:w-[100%]  md:items-center md:justify-between">
            <h1 className="text-[1.9rem] text-left w-full font-hanken md:text-[4.2rem]">{t("TituloClientes")}</h1>
            <p className="md:w-[80%] font-montserrat mx-auto text-[0.9rem] pt-6 md:pt-0 md:text-[1rem]">{t("subtituloClientes")}</p>
          </div>
          <div className="hidden md:block">
            <AboutUsCarrusel />
          </div>

          <div className="mb-[4rem] md:hidden">
            <CommentCard />
            <CommentCard />
            <CommentCard />
          </div>
          <div className="md:hidden">
          <QuienesSomosSingleCardImg />
          </div>
        </section>
      </div>
    </main>
  );
};

export default AboutUs;
